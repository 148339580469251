export default class Features {
    public static Events: string = 'Events';
    public static Offers: string = 'Offers';
    public static Passes: string = 'Passes';
    public static Memberships: string = 'Memberships';
    public static Activities: string = 'Activities';
    public static Spa: string = 'Spa';
    public static Restaurant: string = 'Restaurant';
    public static News: string = 'News';
    public static Transactions: string = 'Transactions';
    public static MyRequests: string = 'MyRequests';
    public static MyAccount: string = 'MyAccount';
    public static ParkInfo: string = 'ParkInfo';
    public static ContactHaulfryn: string = 'ContactHaulfryn';
    public static LodgesDropdown: string = 'LodgesDropdown';
}
