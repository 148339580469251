import React from "react";
import { Desktop, Mobile } from "./ResponsiveViews";
import { ClickableLabel } from "./Labels";
import { ClickableIcon } from "./Icons";
import DropdownList from "./DropdownList";
import { useTranslation } from "react-i18next";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import "./Header.scss";
import * as Routes from "../../../Constants/Routes";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { faBars, faUserCircle } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore
import Fade from "react-reveal/Fade";
import { LinkWidget } from "./Widget";
import history from "../../../History";
import MobileSideNav from "./MobileSideNav";
import NavOption from "./NavOption";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactModal from "./ContactModal";
import HomeButton from "./HomeButton";
import FeatureToggle from "./FeatureToggle";
import Features from "../../../Constants/Features";

interface HeaderProps extends RouteComponentProps {
  logoutClick: () => void;
  options?: [DropdownListOption];
  selectedOption?: DropdownListOption;
  onSelectionChanged?: (newValue: DropdownListOption) => void;
  backgroundImgSrc?: string;
  backgroundContainerClassName?: string;
  isHeaderOpen: boolean;
  setHeaderOpen: (isOpen: boolean) => void;
  contractEndDate: string;
  showMyDetailsLink: boolean;
  openMyContactDetailsModal: () => void;
  setContactHaulfrynModalOpen: (isOpen: boolean) => void;
  isContactHaulfrynModalOpen: boolean;
  hasSublettings: boolean;
  useMemberships: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    options,
    selectedOption,
    location,
    isHeaderOpen,
    setHeaderOpen,
    isContactHaulfrynModalOpen,
    setContactHaulfrynModalOpen,
    openMyContactDetailsModal,
    hasSublettings,
  } = props;
  const { t } = useTranslation();

  history.listen(() => {
    // close modal when route changes
    if (setHeaderOpen) {
      setHeaderOpen(false);
      setContactHaulfrynModalOpen(false);
    }
  });

  const contactHaulfrynMobileClick = () => {
    setHeaderOpen(false);
    setContactHaulfrynModalOpen(true);
  };

  const viewMyDetailsClick = () => {
    setHeaderOpen(false);
    openMyContactDetailsModal();
  };

  return (
    <>
      <Desktop>
        <div className="header">
          <div
            className={classNames(
              "horizontal-background",
              props.backgroundContainerClassName,
              {
                "header-open": isHeaderOpen,
              }
            )}
          >
            {props.backgroundImgSrc ? (
              <img
                className="horizontal-background-image"
                src={props.backgroundImgSrc}
                alt=""
              />
            ) : null}
          </div>
          <Fade top>
            <div className="header-container">
              {location && location.pathname !== "/" && (
                <div className="header-bars-container">
                  <ClickableIcon
                    icon={faBars}
                    clickAction={() => setHeaderOpen(!isHeaderOpen)}
                    className="header-bars"
                  />
                </div>
              )}
              <HomeButton />
              <div className="header-item-container">
                <ClickableLabel
                  className={
                    "header-item clickable-label-header my-details-container " +
                    (props.showMyDetailsLink ? "show" : "hide")
                  }
                  text={t("general.myDetails")}
                  clickAction={openMyContactDetailsModal}
                >
                  <FontAwesomeIcon icon={faUserCircle} />
                </ClickableLabel>
                <FeatureToggle features={[Features.ContactHaulfryn]}>
                  <ClickableLabel
                    className="header-item clickable-label-header"
                    text={t("contactHaulfryn.title")}
                    clickAction={() => setContactHaulfrynModalOpen(true)}
                  />
                </FeatureToggle>
                <FeatureToggle features={[Features.LodgesDropdown]}>
                  {options && props.onSelectionChanged ? (
                    options.length === 1 ? (
                      <div className="header-item">
                        <label>{options[0].label}</label>
                      </div>
                    ) : (
                      <DropdownList
                        className="header-item-dropdown header-select-container"
                        classNamePrefix="header-select"
                        selectedOption={selectedOption}
                        options={options}
                        onSelectionChanged={props.onSelectionChanged}
                      />
                    )
                  ) : null}
                </FeatureToggle>
                {props.contractEndDate && (
                  <div className="header-item contract-container">
                    <div>{t("general.contractEnd")}</div>
                    <div className="contract-end-date">
                      {props.contractEndDate}
                    </div>
                  </div>
                )}
                <ClickableLabel
                  className="header-item clickable-label-header"
                  text={t("general.signOut")}
                  clickAction={props.logoutClick}
                />
              </div>
            </div>
            <Fade
              top
              collapse
              when={isHeaderOpen}
              timeout={500}
              distance="150px"
            >
              <div
                className={classNames("header-menu", { open: isHeaderOpen })}
              >
                <LinkWidget containerClassName="nav-option" to={Routes.Home}>
                  <NavOption
                    text={t("navigation.backToHome")}
                    icon="back-to-home.svg"
                    iconAlt="Back to home"
                  />
                </LinkWidget>
                <FeatureToggle features={[Features.ParkInfo]}>
                  <LinkWidget
                    containerClassName="nav-option"
                    to={Routes.MyParkAndHome}
                  >
                    <NavOption
                      text={t("navigation.myParkAndHome")}
                      icon="my-park-and-home.svg"
                      iconAlt="My park and home"
                    />
                  </LinkWidget>
                </FeatureToggle>
                {props.useMemberships ? (
                  <div className="widget-mobile-container">
                    <LinkWidget
                      to={Routes.MyGuests}
                      id="MyMemberships"
                      containerClassName="nav-option"
                    >
                      <NavOption
                        text={t("navigation.myMemberships")}
                        icon="my-guests.svg"
                        iconAlt="My Members"
                      />
                    </LinkWidget>
                  </div>
                ) : (
                  <div className="widget-mobile-container">
                    <FeatureToggle features={[Features.Passes]}>
                      <LinkWidget
                        to={Routes.MyGuests2}
                        id="MyGuests"
                        containerClassName="nav-option"
                      >
                        <NavOption
                          text={t("navigation.myGuests")}
                          icon="my-guests.svg"
                          iconAlt="My Guests"
                        />
                      </LinkWidget>
                    </FeatureToggle>
                  </div>
                )}
                {hasSublettings && (
                  <LinkWidget
                    containerClassName="nav-option"
                    to={Routes.MyCalendar}
                  >
                    <NavOption
                      text={t("navigation.myCalendar")}
                      icon="my-calendar.svg"
                      iconAlt="My calendar"
                    />
                  </LinkWidget>
                )}
                <FeatureToggle features={[Features.MyAccount]}>
                  <LinkWidget
                    containerClassName="nav-option"
                    to={Routes.MyAccount}
                  >
                    <NavOption
                      text={t("navigation.myAccount")}
                      icon="my-account.svg"
                      iconAlt="My account"
                    />
                  </LinkWidget>
                </FeatureToggle>
                <FeatureToggle features={[Features.MyRequests]}>
                  <LinkWidget
                    containerClassName="nav-option"
                    to={Routes.MyRequestsAndQueries}
                  >
                    <NavOption
                      text={t("navigation.myRequests")}
                      icon="my-requests.svg"
                      iconAlt="My Requests"
                    />
                  </LinkWidget>
                </FeatureToggle>
              </div>
            </Fade>
          </Fade>
        </div>
      </Desktop>
      <Mobile>
        <div
          className={classNames(
            "horizontal-background",
            props.backgroundContainerClassName
          )}
        ></div>
        <div className="mobile-header-container">
          <div>
            {location && location.pathname !== "/" && (
              <ClickableIcon
                className="mobile-clickable-header-item"
                icon={faBars}
                clickAction={() => setHeaderOpen(!isHeaderOpen)}
              />
            )}
          </div>
          <FeatureToggle features={[Features.LodgesDropdown]}>
            <div className="mobile-header-lodge">
              {selectedOption ? selectedOption.label : ""}
            </div>
          </FeatureToggle>
          <ClickableIcon
            className="mobile-clickable-header-item"
            icon={faSignOutAlt}
            clickAction={props.logoutClick}
          />
          <MobileSideNav
            onClose={() => setHeaderOpen(false)}
            isHeaderOpen={isHeaderOpen}
            selectOptions={options}
            selectedOption={selectedOption}
            onSelectionChanged={props.onSelectionChanged}
            openMyContactDetailsModal={viewMyDetailsClick}
            showMyDetailsLink={props.showMyDetailsLink}
            onContactHaulfrynClick={contactHaulfrynMobileClick}
            hasSublettings={hasSublettings}
            useMemberships={props.useMemberships}
          />
        </div>
      </Mobile>
      <ContactModal
        isOpen={isContactHaulfrynModalOpen}
        onBackButtonClick={() => setContactHaulfrynModalOpen(false)}
        contactDetails={{
          Title: t("contactHaulfryn.title"),
          Description: t("contactHaulfryn.description"),
          Address: t("contactHaulfryn.addressValue"),
          PhoneNumber: t("contactHaulfryn.phoneNumberValue"),
          Email: t("contactHaulfryn.emailAddressValue"),
        }}
      />
    </>
  );
};

export default withRouter(Header);
