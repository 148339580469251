import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./MobileSideNav.scss";
import { ClickableIcon } from "./Icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore
import Slide from "react-reveal/Slide";
import { LinkWidget, Widget, ClickableWidget } from "./Widget";
import * as Routes from "../../../Constants/Routes";
import DropdownList from "./DropdownList";
import NavOption from "./NavOption";
import FeatureToggle from "./FeatureToggle";
import Features from "../../../Constants/Features";
import { useTranslation } from "react-i18next";

interface MobileSideNavProps {
  onClose: () => void;
  isHeaderOpen: boolean;
  selectOptions?: DropdownListOption[];
  selectedOption?: DropdownListOption;
  onSelectionChanged?: (newValue: DropdownListOption) => void;
  openMyContactDetailsModal: () => void;
  showMyDetailsLink: boolean;
  onContactHaulfrynClick: () => void;
  hasSublettings: boolean;
  useMemberships: boolean;
}

const MobileSideNav: React.FC<MobileSideNavProps> = ({
  selectOptions,
  selectedOption,
  onSelectionChanged,
  onClose,
  isHeaderOpen,
  openMyContactDetailsModal,
  showMyDetailsLink,
  onContactHaulfrynClick,
  hasSublettings,
  useMemberships,
}) => {
  useEffect(() => {
    // prevent scrolling content behind the nav
    if (isHeaderOpen) {
      document.body.classList.add("kill-scroll");
    }
    return () => {
      document.body.classList.remove("kill-scroll");
    };
  }, [isHeaderOpen]);

  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <Slide left collapse when={isHeaderOpen}>
      <div className="mobile-side-nav">
        <div className="mobile-side-nav-content">
          <div>
            <ClickableIcon
              icon={faTimes}
              clickAction={onClose}
              className="mobile-side-nav-close"
            />
          </div>
          <div className="mobile-side-nav-options">
            <FeatureToggle features={[Features.LodgesDropdown]}>
              {selectOptions && onSelectionChanged ? (
                selectOptions.length === 1 ? (
                  <Widget
                    position={{
                      columnIndex: 1,
                      columnSpan: 12,
                      rowIndex: 1,
                      rowSpan: 1,
                    }}
                    containerClassName="mobile-side-nav-widget-container-mobile"
                  >
                    <div>{selectOptions[0].label}</div>
                  </Widget>
                ) : (
                  <DropdownList
                    className="header-select-container"
                    classNamePrefix="header-select"
                    selectedOption={selectedOption}
                    options={selectOptions}
                    onSelectionChanged={onSelectionChanged}
                  />
                )
              ) : null}
            </FeatureToggle>
            <LinkWidget containerClassName="nav-option" to={Routes.Home}>
              <NavOption
                text={t("navigation.backToHome")}
                icon="back-to-home.svg"
                iconAlt="Back to home"
              />
            </LinkWidget>
            <FeatureToggle features={[Features.ParkInfo]}>
              <LinkWidget
                containerClassName="nav-option"
                to={Routes.MyParkAndHome}
              >
                <NavOption
                  text={t("navigation.myParkAndHome")}
                  icon="my-park-and-home.svg"
                  iconAlt="My park and home"
                />
              </LinkWidget>
            </FeatureToggle>
            {useMemberships ? (
              <LinkWidget containerClassName="nav-option" to={Routes.MyGuests}>
                <NavOption
                  text={t("navigation.myMemberships")}
                  icon="my-guests.svg"
                  iconAlt="My Members"
                />
              </LinkWidget>
            ) : (
              <FeatureToggle features={[Features.Passes]}>
                <LinkWidget containerClassName="nav-option" to={Routes.MyGuests2}>
                  <NavOption
                    text={t("navigation.myGuests")}
                    icon="my-guests.svg"
                    iconAlt="My Guests"
                  />
                </LinkWidget>
              </FeatureToggle>
            )}
            {hasSublettings && (
              <LinkWidget
                containerClassName="nav-option"
                to={Routes.MyCalendar}
              >
                <NavOption
                  text={t("navigation.myCalendar")}
                  icon="my-calendar.svg"
                  iconAlt="My calendar"
                />
              </LinkWidget>
            )}
            {showMyDetailsLink ? (
              <ClickableWidget
                containerClassName="nav-option"
                onClick={openMyContactDetailsModal}
              >
                <NavOption
                  text={t("general.myDetails")}
                  icon="my-requests.svg"
                  iconAlt="My details"
                />
              </ClickableWidget>
            ) : null}
            <FeatureToggle features={[Features.MyAccount]}>
              <LinkWidget containerClassName="nav-option" to={Routes.MyAccount}>
                <NavOption
                  text={t("navigation.myAccount")}
                  icon="my-account.svg"
                  iconAlt="My account"
                />
              </LinkWidget>
            </FeatureToggle>
            <FeatureToggle features={[Features.ContactHaulfryn]}>
              <ClickableWidget
                containerClassName="nav-option"
                onClick={onContactHaulfrynClick}
              >
                <NavOption
                  text={t("contactHaulfryn.title")}
                  icon="contact-haulfryn.svg"
                  iconAlt="Contact Haulfryn"
                />
              </ClickableWidget>
            </FeatureToggle>
            <FeatureToggle features={[Features.MyRequests]}>
              <LinkWidget
                containerClassName="nav-option"
                to={Routes.MyRequestsAndQueries}
              >
                <NavOption
                  text={t("navigation.myRequests")}
                  icon="my-account.svg"
                  iconAlt="My requests"
                />
              </LinkWidget>
            </FeatureToggle>
          </div>
        </div>
      </div>
    </Slide>,
    document.body
  );
};

export default MobileSideNav;
